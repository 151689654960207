import {plugins} from '../plugins';

/** @type {Map<HTMLElement, object>} */
const pluginInstances = new Map();

/**
 * Plugins handler
 * Execute init method for each plugin called via the data-plugin attribute
 * <table data-plugin="zebraPlugin" data-plugin-options='{"even":"#000", "odd":"#CCC" }'>
 */
export function pluginsLoader(rootElement = document) {
    const elements = Array.from(rootElement.querySelectorAll('[data-plugin^="CLEVER"]'));
    for (const [name, plugin] of Object.entries(plugins)) {
        const elems = elements.filter(element => element.dataset.plugin.replace('CLEVER.', '') === name);
        if (!elems.length) {
            continue;
        }
        elems.forEach(element => {
            const oldInstance = getPluginInstance(element);
            if (oldInstance && oldInstance.unbind) {
                oldInstance.unbind();
            }
            const options = element.dataset.pluginOptions ? JSON.parse(element.dataset.pluginOptions) : {};
            const newInstance = new plugin(options);
            pluginInstances.set(element, newInstance);
            newInstance.bind(element);
        });
    }
}

/**
 * @param {HTMLElement} element
 * @return {Object}
 */
export function getPluginInstance(element) {
    return pluginInstances.get(element);
}
