import heart from '!!raw-loader!../../images/icons/heart.svg';
import {html} from 'htm/preact';
import {count, isLoading} from '../stores/wishlist';

export function WishlistCount() {
    if (isLoading.value) {
        return html`
            <span class="wishlist-icon">
                <span class="wishlist-loader"></span>
            </span>
        `;
    }

    return html`
        <span class="wishlist-icon ${count.value ? '' : 'wishlist-empty'}">
            <span dangerouslySetInnerHTML="${{__html: heart}}"></span>
            <span class="wishlist-count">${count}</span>
        </span>
    `;
}
