const $ = window.jQuery;

export class Reviews {
    constructor(options) {
        this.options = $.extend({
            'feedback': '.feedback-item',
            'viewMoreFeedbackCta': '.view-more-comments--cta',
        }, options);
    }

    bind(elem) {
        this.$elem = $(elem);

        const $feedback = this.$elem.find(this.options.feedback);
        const $viewMoreFeedbackCta = this.$elem.find(this.options.viewMoreFeedbackCta);

        // Show 5 first Feedbacks
        $feedback.slice(0, 5).addClass('show-feedback');

        // OnClick CTA show all Feedbacks
        $viewMoreFeedbackCta.click(event => {
            $(event.target).hide();
            $feedback.addClass('show-feedback');
        });
    }
}

