const $ = window.jQuery;

export class ShowMoreFooter {
    constructor(options) {
        this.options = $.extend({
            'showButtonInstitut': '#institut-show-more',
            'institutList': '#institut-list',
            'showButtonTheme': '#theme-show-more',
            'themeList': '#theme-list',
        }, options);
    }

    bind(elem) {
        this.$elem = $(elem);

        const $showButtonInstitut = this.$elem.find(this.options.showButtonInstitut);
        const $institutList = this.$elem.find(this.options.institutList);

        const $showButtonTheme = this.$elem.find(this.options.showButtonTheme);
        const $themeList = this.$elem.find(this.options.themeList);

        $showButtonInstitut.on('click', () => {
            $($showButtonInstitut).toggleClass('up');
            $($institutList).toggleClass('appear');
        });

        $showButtonTheme.on('click', () => {
            $($showButtonTheme).toggleClass('up');
            $($themeList).toggleClass('appear');
        });
    }
}

