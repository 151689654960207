const $ = window.jQuery;

export class Stars {
    constructor(options) {
        this.options = $.extend({
            'feedback': '.feedback-item',
        }, options);
    }

    bind(elem) {
        this.$elem = $(elem);

        const $feedback = this.$elem.find(this.options.feedback);
        $feedback.each((index, element) => {
            // get rate value
            const $rateClass = $(element).find('.rate').attr('class').split(' ')[1];
            const $rateNumber = parseInt($rateClass.replace('rate-', ''));

            // set stars solid style according rate
            const $stars = $(element).find('.far');
            for (let i = 0; i < $rateNumber; i++) {
                $($stars[i]).removeClass('far');
                $($stars[i]).addClass('fas');
            }
        });
    }
}

