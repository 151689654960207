import {Cookies} from './Cookies';
import {ucfirst} from './utils/helpers';

class WishlistCache {

    #storage;
    #cacheKey;
    #cookieKey;

    /**
     * @param {Storage} storage
     * @param {string} cacheKey
     * @param {string} cookieKey
     * @param {string} lang
     */
    constructor(
        storage = window.sessionStorage,
        cacheKey = 'gcaWishlist',
        cookieKey = window.OxianeInstitut.wishlist.cookieName,
        lang = window.OxianeInstitut.lang,
    ) {
        this.#storage = storage;
        this.#cacheKey = `${cacheKey}${ucfirst(lang)}`;
        this.#cookieKey = cookieKey;
    }

    get() {
        const value = this.#storage.getItem(this.#cacheKey);
        if (value === null) {
            return null;
        }
        const {token, posts} = JSON.parse(value);
        if (token !== Cookies.get(this.#cookieKey)) {
            return null;
        }
        return posts;
    }

    set(posts) {
        const token = Cookies.get(this.#cookieKey);
        if (!token) {
            this.delete();
            return;
        }
        this.#storage.setItem(this.#cacheKey, JSON.stringify({
            token,
            posts: posts,
        }));
    }

    delete() {
        this.#storage.removeItem(this.#cacheKey);
    }

    has() {
        return this.get() !== null;
    }

}

export const wishlistCache = new WishlistCache();
