export class ModalInfoForms {
    bind(elem) {
        const modalInfo = elem.closest('#modal-info');
        const formEmail = elem.querySelector('.form-email');
        const formPhone = elem.querySelector('.form-phone');
        const btnFormEmail = elem.querySelector('#btn-form-email');
        const btnFormPhone = elem.querySelector('#btn-form-phone');
        const modalInfoText = elem.querySelector('#modal-info-text');

        modalInfo.addEventListener('close', () => {
            formEmail.classList.add('hidden');
            formPhone.classList.add('hidden');
            btnFormEmail.classList.remove('hidden');
            btnFormPhone.classList.remove('hidden');
            modalInfoText.classList.remove('hidden');
        });

        btnFormEmail.addEventListener('click', () => {
            btnFormEmail.classList.add('hidden');
            btnFormPhone.classList.add('hidden');
            modalInfoText.classList.add('hidden');
            formEmail.classList.remove('hidden');
        });
        btnFormPhone.addEventListener('click', () => {
            btnFormEmail.classList.add('hidden');
            btnFormPhone.classList.add('hidden');
            modalInfoText.classList.add('hidden');
            formPhone.classList.remove('hidden');
        });
    }
}
