import {html} from 'htm/preact';
import {count, isLoading, posts} from '../stores/wishlist';
import {FormationItem} from './FormationItem';

const {__} = wp.i18n;

export function Wishlist() {
    if (isLoading.value) {
        return html`<div class="wishlist-loader"></div>`;
    }
    if (count.value === 0) {
        return html`
            <article class="section--wysiwyg no-formation">
                <h2 class="title">${__('Votre liste de souhait est vide.', 'oxiane-institut')}</h2>
                <p class="helper">${__('Ajoutez-en grâce au bouton "Ajouter à votre liste".', 'oxiane-institut')}</p>
            </article>
        `;
    }
    return html`
        <ul class="tiles">
            ${posts.value.map(post => html`
                <li class="tile" key="post-${post.id}">
                    <${FormationItem} post="${post}"/>
                </li>
            `)}
        </ul>
    `;
}
