import {effect} from '@preact/signals';
import {cities, city, date, dates, distance, distanceChoices} from '../stores/reservationForm';

export class ReservationFormModal {
    bind(elem) {
        const distanceRadioButtons = elem.querySelectorAll('.input-distance input[type="radio"]');
        const dateSelect = elem.querySelector('#date-input');
        const citySelect = elem.querySelector('#city-input');

        distanceRadioButtons.forEach(
            radioButton => radioButton.addEventListener('change', () => distance.value = radioButton.value),
        );
        dateSelect.addEventListener('change', () => date.value = dateSelect.value);
        citySelect.addEventListener('change', () => city.value = citySelect.value);

        effect(() => {
            this._replaceOptions(dateSelect, dates.value);
            this._replaceOptions(citySelect, cities.value);
            dateSelect.value = date.value;
            citySelect.value = city.value;

            distanceRadioButtons.forEach(radioButton => {
                radioButton.checked = radioButton.value === distance.value;
            });

            citySelect.style.display = distance.value === distanceChoices[0] ? 'none' : '';
        });
    }

    /**
     * @param {HTMLSelectElement} select
     * @param {string[]} data
     * @private
     */
    _replaceOptions(select, data) {
        for (let i = select.options.length - 1; i > 0; i--) {
            select.options.remove(i);
        }

        data.forEach(value => select.add(this._createOption(value, value)));
    }

    _createOption(text, value) {
        const option = document.createElement('option');
        option.value = value;
        option.text = value;

        return option;
    }
}
