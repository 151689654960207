export class WishlistFetchError extends Error {
    /**
     * @param {Response} response
     */
    constructor(response) {
        super(`Wishlist query error: ${response.status}`);

        this.response = response;
        this.status = response.status;
    }
}
