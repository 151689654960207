import {computed, signal} from '@preact/signals';
import {Cookies} from '../Cookies';
import {wishlistCache} from '../WishlistCache';
import {WishlistFetchError} from '../WishlistFetchError';

const wishlistEndpointUrl = window.OxianeInstitut.wishlist.endpointUrl;
const wishlistCookie = window.OxianeInstitut.wishlist.cookieName;

export const posts = signal([]);
export const isLoading = signal(false);
export const count = computed(() => posts.value.length);

async function query(postId = '', method = 'GET') {
    const url = `${wishlistEndpointUrl}/${postId}`.replace(/\/$/, '');
    const response = await fetch(url, {method});
    if (!response.ok) {
        throw new WishlistFetchError(response);
    }
    if (response.status === 204) {
        return null;
    }

    return await response.json();
}

export async function getWishlist() {
    const cachedPosts = wishlistCache.get();
    if (cachedPosts) {
        posts.value = cachedPosts;
        return;
    }

    const p = await query();
    posts.value = p;
    wishlistCache.set(posts.value);
}

export async function addToWishlist(postId) {
    const post = await query(postId, 'PUT');
    posts.value = [...posts.value, post];
    wishlistCache.set(posts.value);
}

export async function removeFromWishlist(postId) {
    await query(postId, 'DELETE');
    const index = posts.value.findIndex(post => post.id === postId);
    if (index > -1) {
        posts.value = posts.value.toSpliced(index, 1);
        wishlistCache.set(posts.value);
    }
}

export async function init() {
    if (!Cookies.has(wishlistCookie)) {
        return;
    }
    isLoading.value = true;
    try {
        await getWishlist();
    } finally {
        isLoading.value = false;
    }
}
