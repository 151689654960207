import {liteClient as algoliasearch} from 'algoliasearch/lite';
import {html} from 'htm/preact';
import instantsearch from 'instantsearch.js';
import {FormationItem} from '../components';

export class SearchPlugin {
    constructor({appId, apiKey, indexName}) {
        this.searchClient = algoliasearch(appId, apiKey);
        this.indexName = indexName;
        this.lang = window.OxianeInstitut.lang;
    }

    bind(elem) {
        /** @type {HTMLElement} */
        this.elem = elem;
    }

    createInstantSearch(options) {
        return instantsearch({
            indexName: this.indexName,
            searchClient: this.searchClient,
            insights: {
                insightsInitParams: {
                    useCookie: true,
                },
            },
            future: {
                preserveSharedStateOnUnmount: true,
            },
            ...options,
        });
    }

    itemTemplate(hit, {sendEvent}) {
        return html`
            <${FormationItem} post="${hit}" sendEvent="${sendEvent}" highlight/>`;
    }
}
