import register from 'preact-custom-element';
import {ReservationForm} from './ReservationForm';
import {Wishlist} from './Wishlist';
import {WishlistButton} from './WishlistButton';
import {WishlistCount} from './WishlistCount';

register(ReservationForm, 'oi-reservation-form', ['title', 'accessibility-text']);
register(Wishlist, 'oi-wishlist');
register(WishlistButton, 'oi-wishlist-button', ['post-id']);
register(WishlistCount, 'oi-wishlist-count');
