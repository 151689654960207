import Swiper from 'swiper';
import {Autoplay, Pagination} from 'swiper/modules';

export class Slider {
    constructor(options) {
        this.options = Object.assign({}, options, {
            modules: [Autoplay, Pagination],
        });
    }

    bind(elem) {
        this.swiper = new Swiper(elem, this.options);
    }
}
