export function onClickOutside(target, handler) {
    const listener = event => {
        if (!target || target === event.target || eventPath(event).includes(target)) {
            return;
        }

        handler(event);
    };

    function eventPath(event) {
        const path = (event.composedPath && event.composedPath()) || event.path;

        if (path != null) {
            return path;
        }

        function getParents(node, memo = []) {
            const parentNode = node.parentNode;

            return parentNode
                ? getParents(parentNode, memo.concat([parentNode]))
                : memo;
        }

        return [event.target].concat(getParents(event.target));
    }

    return window.addEventListener('click', listener, {passive: true});
}
