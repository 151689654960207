import closeIcon from '!!raw-loader!../../images/icons/close-circle.svg';
import searchIcon from '!!raw-loader!../../images/icons/search.svg';
import {html, render} from 'htm/preact';
import {connectAutocomplete, connectSearchBox} from 'instantsearch.js/es/connectors';
import {Highlight} from 'instantsearch.js/es/helpers/components';
import {history} from 'instantsearch.js/es/lib/routers';
import {debounce} from 'instantsearch.js/es/lib/utils';
import {configure, hits, index} from 'instantsearch.js/es/widgets';
import MicroModal from 'micromodal';
import {modalConfig} from '../utils/modal';
import {pluginsLoader} from '../utils/pluginsLoader';
import {SearchPlugin} from './SearchPlugin';

const {__} = wp.i18n;

export class SearchModal extends SearchPlugin {
    constructor({appId, apiKey, indexName, querySuggestionsIndexName, wishlist, searchTitleFormat}) {
        super({appId, apiKey, indexName, wishlist});
        this.querySuggestionsIndexName = querySuggestionsIndexName;
        this.searchTitleFormat = searchTitleFormat;
    }

    bind(elem) {
        super.bind(elem);

        /** @type {HTMLElement} */
        this.searchForm = this.elem.querySelector('#search-modal-form');
        /** @type {HTMLElement} */
        this.hitsContainer = this.elem.querySelector('#search-modal-hits');
        /** @type {HTMLAnchorElement} */
        this.allResultsLink = this.elem.querySelector('#search-modal-all-results a');
        this.isSearchPage = document.body.classList.contains('search');

        if (!this.searchForm) {
            return;
        }

        window.addEventListener('keydown', event => {
            if (event.key === 'k' && event.ctrlKey) {
                event.preventDefault();
                MicroModal.show('modal-search', modalConfig);
            }
        });

        this.search = this.buildSearch();
        this.search.start();
    }

    buildSearch() {
        const search = this.createInstantSearch({
            onStateChange: ({uiState, setUiState}) => {
                const query = uiState[this.indexName].query || '';
                this.elem.dispatchEvent(new CustomEvent('search', {detail: {query}}));
                if (this.isSearchPage) {
                    document.title = this.searchTitleFormat.replace('%%searchphrase%%', query);
                }
                setUiState(uiState);
            },
            routing: this.isSearchPage ? {
                router: history(),
                stateMapping: {
                    stateToRoute: uiState => {
                        const state = uiState[this.indexName];
                        return {
                            s: state.query,
                        };
                    },
                    routeToState: routeState => {
                        if (!Object.entries(routeState).length) {
                            return {};
                        }

                        return {
                            [this.indexName]: {
                                query: routeState.s || '',
                            },
                        };
                    },
                },
            } : false,
        });

        const searchBox = this.searchBoxWidget();
        const autocomplete = this.autocompleteWidget();
        const allResultsLink = this.allResultsLinkWidget();

        search.addWidgets([
            index({indexName: this.querySuggestionsIndexName}).addWidgets([
                configure({
                    hitsPerPage: 5,
                    filters: '',
                }),
            ]),
            configure({
                hitsPerPage: 3,
                filters: `lang:${this.lang}`,
            }),
            searchBox({
                container: this.searchForm,
            }),
            autocomplete({
                container: this.searchForm.querySelector('.search-modal-query-suggestion-list'),
            }),
            hits({
                container: this.hitsContainer,
                templates: {
                    item: this.itemTemplate.bind(this),
                    empty: this.noResultsTemplate.bind(this),
                },
            }),
            allResultsLink({
                container: this.allResultsLink,
            }),
        ]);
        // Reload plugins after
        search.on('render', debounce(() => pluginsLoader(this.hitsContainer), 100));

        return search;
    }

    searchBoxWidget() {
        return connectSearchBox(({query, refine, widgetParams}, isFirstRender) => {
            const form = widgetParams.container;
            const input = form.querySelector('#search');

            if (isFirstRender) {
                form.addEventListener('submit', event => {
                    if (!this.isSearchPage) {
                        return;
                    }
                    event.preventDefault();
                    refine(input.value);
                    MicroModal.close('modal-search');
                });
                input.addEventListener('input', () => refine(input.value));
            }

            input.value = query;
        });
    }

    autocompleteWidget() {
        return connectAutocomplete(
            ({indices, refine, widgetParams, currentRefinement}) => {
                const {container: list} = widgetParams;

                const querySuggestionsIndex = indices.find(index => index.indexName === this.querySuggestionsIndexName);
                if (!querySuggestionsIndex) {
                    render(html``, list);
                    return;
                }

                const listItems = querySuggestionsIndex.hits.map(hit => html`
                    <li>
                        <button type="button"
                                onClick="${() => refine(hit.query === currentRefinement ? '' : hit.query)}">
                            <span dangerouslySetInnerHTML="${{__html: searchIcon}}"></span>
                            <span>
                                <${Highlight} attribute="query" hit="${hit}"/>
                            </span>
                            ${hit.query === currentRefinement && html`<span dangerouslySetInnerHTML="${{__html: closeIcon}}"></span>`}
                        </button>
                    </li>
                `);

                render(html`${listItems}`, list);
            },
        );
    }

    allResultsLinkWidget() {
        return connectSearchBox(({query, widgetParams}, isFirstRender) => {
            const link = widgetParams.container;

            if (isFirstRender && this.isSearchPage) {
                link.addEventListener('click', event => {
                    event.preventDefault();
                    MicroModal.close('modal-search');
                });
            }

            link.href = `/?s=${query}#search-results`;
        });
    }

    noResultsTemplate(data) {
        if (!data.query) {
            return html``;
        }
        return html`
            <div class="search-modal-no-results">
                <p>
                    ${__('Nous sommes désolés, mais aucun résultat n’est disponible pour cette recherche.', 'oxiane-institut')}
                </p>
            </div>
        `;
    }
}

