import {html} from 'htm/preact';
import {connectSearchBox} from 'instantsearch.js/es/connectors';
import {history} from 'instantsearch.js/es/lib/routers';
import {debounce} from 'instantsearch.js/es/lib/utils';
import {configure, hits, hitsPerPage, pagination, stats} from 'instantsearch.js/es/widgets';
import MicroModal from 'micromodal';
import {modalConfig} from '../utils/modal';
import {pluginsLoader} from '../utils/pluginsLoader';
import {SearchPlugin} from './SearchPlugin';

const {__, _n, sprintf} = wp.i18n;

export class SearchResults extends SearchPlugin {
    bind(elem) {
        super.bind(elem);

        /** @type {HTMLElement} */
        this.stats = this.elem.querySelector('#search-results-stats');
        /** @type {HTMLElement} */
        this.hits = this.elem.querySelector('#search-results-hits');
        /** @type {HTMLElement} */
        this.pagination = this.elem.querySelector('#search-results-pagination');
        /** @type {HTMLElement} */
        this.hitsPerPage = this.elem.querySelector('#search-results-hits-per-page');
        this.searchModal = document.querySelector('[data-plugin="CLEVER.SearchModal"]');

        this.search = this.buidSearch();
        this.search.start();

        this.searchModal.addEventListener('search', ({detail: {query}}) => {
            this.search.setUiState({
                [this.indexName]: {query},
            });
        });
    }

    buidSearch() {
        const search = this.createInstantSearch({
            routing: {
                router: history(),
                stateMapping: {
                    stateToRoute: uiState => {
                        const state = uiState[this.indexName];
                        return {
                            s: state.query,
                            page: state.page,
                            perPage: state.hitsPerPage,
                        };
                    },
                    routeToState: routeState => {
                        if (!Object.entries(routeState).length) {
                            return {};
                        }

                        return {
                            [this.indexName]: {
                                query: routeState.s || '',
                                page: routeState.page,
                                hitsPerPage: routeState.perPage,
                            },
                        };
                    },
                },
            },
        });

        const virtualSearchBox = connectSearchBox(() => {});

        search.addWidgets([
            virtualSearchBox({}),
            configure({
                filters: `lang:${this.lang}`,
            }),
            stats({
                container: this.stats,
                templates: {
                    text: this.statsTemplate.bind(this),
                },
            }),
            hits({
                container: this.hits,
                cssClasses: {
                    root: ['section', 'section--formation-list'],
                    list: 'tiles',
                    item: 'tile',
                },
                templates: {
                    item: this.itemTemplate.bind(this),
                    empty: this.noResultsTemplate.bind(this),
                },
            }),
            pagination({
                container: this.pagination,
                cssClasses: {
                    root: 'pagination',
                    list: 'nav-links',
                    item: 'page-numbers',
                    firstPageItem: 'first',
                    previousPageItem: 'prev',
                    nextPageItem: 'next',
                    lastPageItem: 'last',
                    selectedItem: 'current',
                },
                padding: 1,
            }),
            hitsPerPage({
                container: this.hitsPerPage,
                items: [10, 20, 50].map((value, index) => ({
                    label: value,
                    value,
                    default: index === 0,
                })),
            }),
        ]);
        search.on('render', debounce(() => pluginsLoader(this.hits), 100));

        return search;
    }

    statsTemplate(data) {
        this.elem.classList.toggle('section--domains', data.nbHits > 0);

        return html`
            <p class="result">
                <span>
                    <strong>${data.nbHits}</strong>
                    ${data.query ?
                        sprintf(_n(' résultat de recherche pour "%s"', ' résultats de recherche pour "%s"', data.nbHits, 'oxiane-institut'), data.query) :
                        _n(' résultat de recherche', ' résultats de recherche', data.nbHits, 'oxiane-institut')}
                </span>
                <span>-</span>
                <button type="button" class="button" onClick="${() => MicroModal.show('modal-search', modalConfig)}">
                    ${__('Modifier ma recherche', 'oxiane-institut')}
                </button>
            </p>
        `;
    }

    noResultsTemplate(data) {
        return html`
            <div class="wrap">
                <div class="content">
                    <p class="search-title">${data.query}</p>
                    <p class="search-no-result">${__('Aucun résultat trouvé', 'oxiane-institut')}</p>
                </div>
            </div>
        `;
    }
}
