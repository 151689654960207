import {html} from 'htm/preact';
import {Highlight} from 'instantsearch.js/es/helpers/components';
import {useMemo} from 'preact/hooks';
import {WishlistButton} from './WishlistButton';

const {__, sprintf} = wp.i18n;

export function FormationItem({post, highlight, sendEvent}) {
    const nextSession = useMemo(() => {
        if (!(post.sessions && post.sessions.length)) {
            return null;
        }
        const nextSession = post.sessions.find(session => session.occursAt >= Date.now() / 1000);
        if (!nextSession) {
            return null;
        }
        return {
            ...nextSession,
            occursAt: new Date(nextSession.occursAt * 1000).toLocaleDateString(),
        };
    }, [post.sessions]);

    return html`
        <article class="formation-item">
            <div class="formation-header">
                ${nextSession ? html`
                    <div class="next-date">
                        <strong>${nextSession.occursAt}</strong>
                        <span>${sprintf(__(' à %s', 'oxiane-institut'), nextSession.place)}</span>
                    </div>
                ` : html`
                    <div class="on-demand">${__('Sur demande', 'oxiane-institut')}</div>
                `}

                ${post.isSeminar ? html`
                    <div class="formation-seminar">
                        <img src="/wp-content/themes/oxiane-institut/assets/images/icons/Union.svg" alt=""/>
                        ${__('Séminaire', 'oxiane-institut')}
                    </div>
                ` : ''}
            </div>

            <p class="price">
                ${post.price
                    ? sprintf(__('%s HT', 'oxiane-institut'), post.price)
                    : __('Sur demande', 'oxiane-institut')}
            </p>

            <h2 class="title">
                ${highlight ? html`
                    <a href="${post.permalink}"
                       onClick="${() => sendEvent ? sendEvent('click', post, 'Course Clicked') : null}">
                        <${Highlight} attribute="title" hit="${post}"/>
                    </a>
                ` : html`
                    <a href="${post.permalink}">${post.title}</a>
                `}
            </h2>

            <div class="formation-main">
                <div class="description">
                    <p class="excerpt">
                        ${highlight
                            ? html`
                                <${Highlight} attribute="subtitle" hit="${post}"/>`
                            : post.subtitle}
                    </p>
                </div>

                <p class="time">
                    ${sprintf(__('%d jours', 'oxiane-institut'), post.durationDays)}
                    ${post.durationHours ? sprintf(__(' (%d h)', 'oxiane-institut'), post.durationHours) : ''}
                </p>
            </div>

            <div class="wishlist-button-container">
                <${WishlistButton} postId="${post.id}"/>
            </div>

            <a class="button"
               href="${post.permalink}"
               onClick="${() => sendEvent ? sendEvent('click', post, 'Course Clicked') : null}">
                ${__('Détails', 'oxiane-institut')}
            </a>
        </article>
    `;
}
