export class GtmPush {
    constructor(options) {
        this.options = options;
    }

    bind(elem) {
        this.elem = elem;

        this.elem.addEventListener(this.options.event, () => this.push());
    }

    push() {
        if (!'dataLayer' in window) {
            return;
        }

        window.dataLayer.push(this.options.args);
    }
}

