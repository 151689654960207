import MicroModal from 'micromodal';
import {city, date} from '../stores/reservationForm';
import {modalConfig} from '../utils/modal';

export class Formation {
    constructor(options) {
        this.options = Object.assign({}, {
            showButtonFormation: '#formation-show-more',
            formationList: '#formation-list',
            reservation: '.reservation',
        }, options);
    }

    bind(elem) {
        this.elem = elem;

        this.setupReservationModal();
        this.sessionsShowMore();

        if (window.screen.width < 1024) {
            this.mobileToggleSection();
        }

        document.addEventListener('wpcf7submit', event => {
            const modal = event.target.closest('.modal');
            if ( modal ) {
              setTimeout(() => modal.scrollTo(0, modal.scrollHeight), 300);
            }
        });
    }

    setupReservationModal() {
        this.elem.querySelectorAll(this.options.reservation).forEach(button => {
            button.addEventListener('click', () => {
                MicroModal.show('modal-reservation', modalConfig);
                const card = button.closest('.card-session');
                const session = JSON.parse(card.dataset.session);
                date.value = session.date;
                city.value = session.city;
            });
        });
    }

    sessionsShowMore() {
        const showButtonFormation = this.elem.querySelector(this.options.showButtonFormation);
        if (!showButtonFormation) {
            return;
        }
        const formationList = this.elem.querySelector(this.options.formationList);
        showButtonFormation.addEventListener('click', () => {
            showButtonFormation.classList.toggle('up');
            formationList.classList.toggle('formation-extract');
        });
    }

    mobileToggleSection() {
        const sectionsItem = document.querySelectorAll('.wrap-item');
        sectionsItem.forEach(sectionItem => {
            let itemTitle = sectionItem.querySelector('.subitem-title');
            if (itemTitle) {
                let itemContent = itemTitle.nextElementSibling;
                itemContent.classList.add('item-content');
            }
        });

        const sectionsTitle = document.querySelectorAll('.subitem-title');
        if (sectionsTitle) {
            sectionsTitle.forEach((sectionTitle, sectionContent) => {
                sectionTitle.addEventListener('click', event => {
                    event.preventDefault();

                    event.target.parentNode.classList.toggle('on');

                    sectionsTitle.forEach((sectionTitle2, sectionContent2) => {
                        if (sectionContent !== sectionContent2) {
                            sectionTitle2.parentNode.classList.remove('on');
                        }
                    });
                });
            });
        }
    }
}

