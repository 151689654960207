const $ = window.jQuery;

export class IframeResizer {
    constructor() {
        this.iframeList = [
            'iframe[src*="//www.youtube.com"]',
            'iframe[src*="//www.dailymotion.com"]',
            'iframe[src*="//player.vimeo.com"]',
            'iframe[src*="//www.slideshare.net"]',
        ];
    }

    bind(elem) {
        this.$elem = $(elem);
        this.$allVideos = this.$elem.find(this.iframeList.toString());

        if (!this.$allVideos.length) {
            return false;
        }

        this.$allVideos.each((index, element) => {
            $(element)
                .data('aspectRatio', element.height / element.width)
                .removeAttr('height')
                .removeAttr('width');
        });

        $(window).on('resize.iframeResizer', event => {
            this.$allVideos.each(() => {
                const $el = $(event.target);
                const newWidth = $el.parent().width();

                $el
                    .width(newWidth)
                    .height(newWidth * $el.data('aspectRatio'));
            });
        }).resize();
    }

    unbind() {
        $(window).off('resize.iframeResizer');
    }
}

