import {html} from 'htm/preact';
import MicroModal from 'micromodal';
import {cities, city, date, dates, distance, distanceChoices} from '../stores/reservationForm';
import {modalConfig} from '../utils/modal';

const {__} = wp.i18n;

export function ReservationForm({title, accessibilityText}) {
    function onSubmit(event) {
        event.preventDefault();
        MicroModal.show('modal-reservation', modalConfig);
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'CTA_inscription',
            });
        }
    }

    return html`
        <p class="title">${title}</p>
        <form id="session-reservation-form" class="wpcf7" onSubmit="${onSubmit}">
            <div class="wpcf7-form">
                <div class="input_container input_container_radio">
                    <div class="wpcf7-form-control wpcf7-radio input-distance">
                        ${distanceChoices.map(choice => html`
                            <span class="wpcf7-list-item first">
                            <label>
                                <input type="radio"
                                       name="distance"
                                       value="${choice}"
                                       required
                                       checked="${distance.value === choice}"
                                       onChange="${() => distance.value = choice}"/>
                                <span class="wpcf7-list-item-label">${choice}</span>
                            </label>
                        </span>
                        `)}
                    </div>
                </div>
                <div class="input_container input_container_select">
                    <label for="input-date">${__('Date de la session*', 'oxiane-institut')}</label>
                    <select id="input-date"
                            name="date"
                            class="wpcf7-select input-date"
                            required
                            value="${date.value}"
                            onChange="${event => date.value = event.target.value}">
                        <option value="">${__('Séléctionner une date', 'oxiane-institut')}</option>
                        ${dates.value.map(date => html`
                            <option value="${date}">${date}</option>
                        `)}
                    </select>
                </div>
                ${distance.value === distanceChoices[1] && html`
                    <div class="input_container input_container_select">
                        <label for="input-city">${__('Lieux de la session*', 'oxiane-institut')}</label>
                        <select id="input-city"
                                name="city"
                                class="wpcf7-select input-city"
                                required
                                value="${city.value}"
                                onChange="${event => city.value = event.target.value}">
                            <option value="">${__('Séléctionner une ville', 'oxiane-institut')}</option>
                            ${cities.value.map(city => html`
                                <option value="${city}">${city}</option>
                            `)}
                        </select>
                    </div>
                `}
            </div>
            <p class="accessibility-text" dangerouslySetInnerHTML="${{__html: accessibilityText}}"></p>
            <button type="submit" class="button reverse">${__('Inscription', 'oxiane-institut')}</button>
        </form>
    `;
}
