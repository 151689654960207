export class BurgerMenu {
    constructor(options) {
        this.isMenuOpen = options.menuOpen || false;
    }

    bind(elem) {
        this.elem = elem;
        this.button = this.elem.querySelector('.header-menu-button');
        this.menu = this.elem.querySelector('.header-menu');
        this.subMenuButtons = Array.from(this.menu.querySelectorAll('.menu-item-button'));

        this.menuToggle();
        this.subMenuToggle();
    }

    menuToggle() {
        this.button.addEventListener('click', () => {
            this.isMenuOpen = !this.isMenuOpen;
            this.#renderMenuButton();
        });
        this.#renderMenuButton();
    }

    #renderMenuButton() {
        this.button.classList.toggle('open', this.isMenuOpen);
        this.menu.classList.toggle('open', this.isMenuOpen);
    }

    subMenuToggle() {
        this.subMenuButtons.forEach(button => button.addEventListener('click', () => {
            const subMenu = button.nextElementSibling;
            button.classList.toggle('open');
            subMenu.classList.toggle('open');
        }));

        const subMenu = this.menu.querySelector('.sub-menu:has(.current-menu-item)');
        if (subMenu && window.innerWidth < 1024) {
            subMenu.classList.add('open');
        }
    }
}

