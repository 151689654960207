const $ = window.jQuery;

export class Filter {
    constructor(options) {
        this.options = $.extend({
            'domains_select': '.domains-select',
        }, options);
    }

    bind(elem) {
        this.$elem = $(elem);
        const $domains_select = this.$elem.find(this.options.domains_select);

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('domain');

        if (myParam) {
            $domains_select.val(myParam);
        }

        $domains_select.change(function () {
            $('#filter-form').submit();
        });
    }
}

