if (process.env.NODE_ENV === 'development') {
    require('preact/debug');
}
import MicroModal from 'micromodal';
import {IframeResizer} from './plugins/IframeResizer';
import {init as initWishlist} from './stores/wishlist';
import {modalConfig} from './utils/modal';
import {pluginsLoader} from './utils/pluginsLoader';
import './components/webComponents';

// Load all SVGs to combine them into a sprite
require.context('../images/svg', true, /\.svg$/);

initWishlist();

document.addEventListener('DOMContentLoaded', () => {
    MicroModal.init(modalConfig);

    pluginsLoader();

    new IframeResizer().bind(window);
});
