export class Cookies {

    static get(key) {
        return this.#unserializeCookie()[key];
    }

    /**
     * @param {string} key
     * @return {boolean}
     */
    static has(key) {
        return document.cookie.includes(key);
    }

    /**
     * @param {string} cookie
     * @return {Object}
     */
    static #unserializeCookie(cookie = document.cookie) {
        return Object.fromEntries(cookie.split('; ').map(cookie => cookie.split('=')));
    }

}
