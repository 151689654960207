import heart from '!!raw-loader!../../images/icons/heart.svg';
import {useComputed, useSignal} from '@preact/signals';
import {html} from 'htm/preact';
import {addToWishlist, isLoading as isWishlistLoading, removeFromWishlist, posts} from '../stores/wishlist';

const {__} = wp.i18n;

export function WishlistButton({postId}) {
    postId = parseInt(postId);

    const isLoading = useSignal(false);

    const isWishlisted = useComputed(() => {
        return !!posts.value.find(p => p.id === postId);
    });
    const buttonClass = useComputed(() => {
        if (isLoading.value) {
            return 'wishlist-button-loading';
        }
        if (isWishlisted.value) {
            return 'wishlist-button-active';
        }
        return '';
    });
    const buttonText = useComputed(() => {
        return isWishlisted.value
            ? __('Retirer de votre liste', 'oxiane-institut')
            : __('Ajouter à votre liste', 'oxiane-institut');
    });

    async function toggle() {
        isLoading.value = true;
        try {
            if (isWishlisted.value) {
                await removeFromWishlist(postId);
            } else {
                await addToWishlist(postId);
            }
        } finally {
            isLoading.value = false;
        }
    }

    if (isWishlistLoading.value) {
        return html`<span class="wishlist-loader"></span>`;
    }

    return html`
        <button type="button"
                class="wishlist-button ${buttonClass.value}"
                onClick="${() => toggle()}"
                disabled="${isLoading.value ? 'disabled' : ''}">
            <span class="wishlist-button-icon" dangerouslySetInnerHTML="${{__html: heart}}"></span>
            <span class="wishlist-loader"></span>
            <span class="wishlist-button-text">${buttonText}</span>
        </button>
    `;
}
