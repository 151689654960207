import {computed, signal} from '@preact/signals';

const {__} = wp.i18n;

export const distanceChoices = [
    __('Distanciel', 'oxiane-institut'),
    __('Présentiel', 'oxiane-institut'),
];

export const sessions = signal(window.OxianeInstitut.formationSessions || []);
export const distance = signal(distanceChoices[1]);
export const date = signal('');
export const city = signal('');

export const dates = computed(() => {
    return sessions.value
        .filter(session => city.value ? session.city === city.value : true)
        .map(session => session.date);
});

export const cities = computed(() => {
    return Array.from(new Set(
        sessions.value
            .filter(session => date.value ? session.date === date.value : true)
            .map(session => session.city),
    ));
});
