import {onClickOutside} from '../utils/onClickOutside';

export class LangSwitcher {
    bind(elem) {
        const button = elem.querySelector('.button-toggle-languages');
        const list = elem.querySelector('.language-list');

        button.addEventListener('click', () => elem.classList.toggle('is-open'));
        onClickOutside(elem, () => elem.classList.remove('is-open'));
    }
}
